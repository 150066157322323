import Link from '@components/Common/Link';
import styles from './style.module.css';
import {LocaleEnum} from '@enums/locale';
import {ComponentWithChildrenProps} from '@interfaces/component';

interface CardProps extends ComponentWithChildrenProps {
  isLink?: boolean;
  classNames?: string;
  href?: string;
  locale?: LocaleEnum;
}
export default function Card(props: CardProps) {
  const {children, classNames = '', isLink = false, ...rest} = props;
  return isLink ? (
    <Link {...rest} classNames={`${styles.card} ${classNames}`}>
      {children}
    </Link>
  ) : (
    <div className={`${styles.card} ${classNames}`}>{children}</div>
  );
}
