import Head from 'next/head';

import MainLayout from '../layouts/MainLayout';
import {serverSideTranslations} from 'next-i18next/serverSideTranslations';
import ErrorPageComponent from '@components/ErrorPageComponent';
import {GetStaticPropsContext} from 'next';
export default function Error404() {
  return (
    <MainLayout>
      <Head>
        <title>Liune - 404</title>
        <meta name="description" content="Liune" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <ErrorPageComponent statusCode={404} />
    </MainLayout>
  );
}

export async function getStaticProps({locale}: GetStaticPropsContext) {
  return {
    props: {
      ...(await serverSideTranslations(locale!, ['common'])),
    },
  };
}
