import Card from '@components/Common/Card';
import Image from 'next/image';
import notFound from '@images/404-error.png';
import serverError from '@images/500-error.png';
import Button from '@components/Forms/Button';
import styles from './style.module.css';
import {useTranslation} from 'next-i18next';

export default function ErrorPageComponent({statusCode}: {statusCode: number}) {
  const {t} = useTranslation('common');
  const title =
    statusCode === 500
      ? t<string>('error_page.500.title')
      : t<string>('error_page.404.title');
  return (
    <div className={'container-lg my-5'}>
      <Card>
        <div className={styles.errorContainer}>
          <div className={styles.errorContent}>
            <Image
              alt={title}
              src={statusCode === 500 ? serverError : notFound}
              height={200}
              width={200}
            />
            <h2> {title}</h2>
            <p>
              {statusCode === 500
                ? t<string>('error_page.500.message')
                : t<string>('error_page.404.message')}
            </p>
            <Button
              variant={'link'}
              href={'/'}
              classNames={styles.button}
              size={'lg'}
            >
              {t<string>('error_page.return_home')}
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
}
